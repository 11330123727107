let serviceList = [
  {
    title: "Supply of expensive drugs and other needs to the patients.",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    img: "assets/img/icon/s1.svg",
  },
  {
    title: "Suppling the necessary equipments and chemicals to the Labs.",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    img: "assets/img/icon/s2.svg",
  },
  {
    title: "Fanancial Support to those who in need for the tests to be done from outside.",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    img: "assets/img/icon/s3.svg",
  },
  {
    title: "Helping both patient and the family.",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    img: "assets/img/icon/s4.svg",
  },
  {
    title: "Medical camps and seminars to protect public from Kidney Problems.",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    img: "assets/img/icon/s5.svg",
  },

  {
    title: "Psychological counseling of patients and family members.",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    img: "assets/img/icon/s6.svg",
  },
];

export default serviceList;
