import React from "react";
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { FaFax } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";

const CounterAreaFour = () => {
  return (
    <>
      {/*================= counter area start {/*=================*/}
      <div className="counter-area mg-top--100">
        <div className="container pd-bottom-50">
          <div
            className="bg-base counter-4-area p-3 border-radius-20"
            data-aos="fade-up"
            data-aos-delay="500"
            data-aos-duration="1500"
          >
            <div className="row">
              {/*
              <div className='col-lg-3 align-self-center col-md-6 '>
                <div className='single-counter-inner'>
                  <h5 className='counter-4-title'>
                    KKPS.LK<br /> 
                  </h5>
                </div>
              </div>
              */}
              <div className="col-lg-3 col-md-6">
                <div className="single-counter-inner media">
                  <div className="thumb media-left pe-2">
                    <div className="icon">
                      <FaPhoneAlt />
                    </div>
                    {/*  <img src='assets/img/icon/33.svg' alt='img' /> */}
                  </div>
                  <div className="media-body align-self-center">
                    <p className="text-white mb-0">Hotline</p>
                    <h5 className="text-white mb-1">+94 81 220 0449</h5>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 ">
                <div className="single-counter-inner media">
                  <div className="thumb media-left pe-2">
                    <div className="icon">
                      <IoMail />
                    </div>
                    {/*  <img src='assets/img/icon/33.svg' alt='img' /> */}
                  </div>
                  <div className="media-body align-self-center">
                    <p className="text-white mb-0">Email</p>
                    <h5 className="text-white mb-1">info@kkps.lk</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 ">
                <div className="single-counter-inner media">
                  <div className="thumb media-left pe-2">
                    <div className="icon">
                      <FaFax />
                    </div>
                    {/*  <img src='assets/img/icon/33.svg' alt='img' /> */}
                  </div>
                  <div className="media-body align-self-center">
                    <p className="text-white mb-0">Fax</p>
                    <h5 className="text-white mb-1">+94 81 220 0449</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 ">
                <div className="single-counter-inner media">
                  <div className="thumb media-left pe-2">
                    <div className="icon">
                      <IoLocationSharp />
                    </div>
                    {/*  <img src='assets/img/icon/33.svg' alt='img' /> */}
                  </div>
                  <div className="media-body align-self-center">
                    <p className="text-white mb-0">Office</p>
                    <h5 className="text-white mb-1">
                      National Hospital, Kandy.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div
            className="section-2 border-radius-20"
            data-aos="fade-up"
            data-aos-delay="500"
            data-aos-duration="1500"
          >
            <div className="row d-flex justify-content-around">
              <div className="col-lg-5 col-md-6">
                <h5>Kandy Kidney Protection Society</h5>
                <h3>Established in 1997</h3>
              </div>

              <div className="col-lg-7 align-self-center col-md-6 ">
                <p className="mb-0">
                  {" "}
                  Kandy Kidney Protection Society is a registered organization
                  (No. 97/10) in the Central province, started in 1997 to help
                  the Kidney patients who are in need.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*{/*================= counter area end {/*=================*/}
    </>
  );
};

export default CounterAreaFour;
