import React from "react";
import { FaHeart } from "react-icons/fa";
import { Link } from "react-router-dom";

const AboutAreaFour = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      {/* =============== About Area Three End ===============*/}
      <div className="about-area pd-top-90 pd-bottom-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="about-thumb-inner p-xl-5 pt-4 "
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="1500"
              >
                <img
                  className="animate-main-img"
                  src="assets/img/about/14.png"
                  alt="img"
                />
                <img
                  className="main-img m-md-4"
                  src="assets/img/about/about_bnr.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 align-self-center "
              data-aos="fade-left"
              data-aos-delay="250"
              data-aos-duration="1500"
            >
              <div className="section-title mt-5 mt-lg-0">
                <h6 className="sub-title"> Kandy Kidney Protection Society</h6>
                <h2 className="title">
                  Why Support <span>Kandy Kidney Protection Society</span> ?
                </h2>
                <p className="content mb-4 mb-xl-5">
                  Please Help Save Lives Of Children & Adults With Kidney
                  Disease. We are urgently seeking donations to purchase
                  lifesaving drugs for patients with kidney diseases at National
                  Hospital – Kandy.
                </p>

                <Link
                  className="mt-3 btn btn-border-base"
                  data-aos="fade-right"
                  data-aos-delay="400"
                  data-aos-duration="1500"
                  to="/donation"
                  onClick={scrollToTop}
                >
                  Make a Donation <FaHeart />
                </Link>

                {/*
                <div className='media box-shadow p-3 border-radius-5 mb-3'>
                  <div className='media-left me-3'>
                    <img src='assets/img/icon/31.svg' alt='img' />
                  </div>
                  <div className='media-body'>

                  <Link
                      className='mt-3 btn btn-border-base'
                      data-aos='fade-right'
                      data-aos-delay='400'
                      data-aos-duration='1500'
                      to='/about'
                    >
                      Make a Donation  <FaHeart />
                    </Link>

                    <div className='single-progressbar'>
                      
                    

                    </div>
                  </div>
                </div>
                <div className='media box-shadow p-3 border-radius-5 mb-3'>
                  <div className='media-left me-3'>
                    <img src='assets/img/icon/32.svg' alt='img' />
                  </div>
                  <div className='media-body'>
                    <div className='single-progressbar'>
                      <h6>Cloud Solutions</h6>
                      <div className='skill'>
                        <div
                          style={{ width: "80%" }}
                          className='skill-bar skill1 wow slideInLeft animated'
                        ></div>
                        <p className='skill-count1'>
                          <span className='counter'>80</span> %
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
*/}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =============== About Area Three End ===============*/}
    </>
  );
};

export default AboutAreaFour;
