import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import donationList from "../scripts/donationList";

const TeamAreaGroup = () => {
  return (
    <>
      <div className="service-area bg-relative pd-top-100">
        <img
          className="position-bottom-left top_image_bounce"
          src="assets/img/icon/4.png"
          alt="img"
        />
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">MAKE A DONATION</h6>
            <h3 className="title">
              Help Us to <span>Help Them !</span>
            </h3>
            <p className="content mb-0">
              Life can be hard when someone is coping with a long term illness
              like kidney disease and it can affect his/her ability to work and
              live life to the full. Your contribution to the Kandy Kidney
              Protection Society will go a long way in touching the lives of
              those affected by kidney diseases.
            </p>
          </div>
          <div className="row">
            {donationList.map((data, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <div className="single-service-inner text-center">
                  <div className="thumb">
                    <img src={data.img} alt="img" />
                  </div>
                  <div className="details">
                    <h5>
                      {data.title}
                      {/*
                      <Link to='/service-details'>{data.title}</Link>
                      */}
                    </h5>

                    <p>
                      Account Number : {data.acc}
                      <br />
                      Bank Code : {data.bank}
                      <br />
                      Branch Code : {data.branch}
                      <br />
                      Swift Code : {data.swift}
                    </p>

                    {/*
                    <p>{data.des}</p>


                    <Link className='btn btn-border-base' to='/service-details'>
                      Touch More <FaPlus />
                    </Link>
*/}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamAreaGroup;
