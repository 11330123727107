import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavbarThree from "../components/NavbarThree";
import DonationPage from "../components/DonationPage";
import FooterTwo from "../components/FooterTwo";

const Team = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavbarThree />

      {/* Breadcrumb */}
      <Breadcrumb title={"Donation"} />

      {/* Team Area Group */}
      <DonationPage />

      {/* Footer One */}
      <FooterTwo />
    </>
  );
};

export default Team;
