import {
  FaChevronRight,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

const FooterTwo = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000 * 60); // Update every minute to handle year change during runtime
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      {/* ================== Footer Two Start ==================*/}
      <footer className="footer-area footer-area-2 bg-gray mt-0 pd-top-70">
        <div className="container footer-t pt-5 ">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_about">
                <div className="thumb">
                  <img src="assets/img/kkps_logo-bgr.png" alt="img" />
                </div>
                <div className="details text-center">
                  <p></p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Quick Links</h4>
                <ul>
                  <li>
                    <Link to="/" onClick={scrollToTop}>
                      <FaChevronRight /> Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/service" onClick={scrollToTop}>
                      <FaChevronRight /> Services
                    </Link>
                  </li>

                  {/*
                  <li>
                    <Link to='/blog'>
                      <FaChevronRight /> Blog
                    </Link>
                  </li>
                  */}

                  <li>
                    <Link to="/about" onClick={scrollToTop}>
                      <FaChevronRight /> About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" onClick={scrollToTop}>
                      <FaChevronRight /> Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Important Links</h4>
                <ul>
                  <li>
                    <Link to="/donation" onClick={scrollToTop}>
                      <FaChevronRight /> Make a Donation
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <FaChevronRight /> National Hospital, Kandy.
                    </Link>
                  </li>
                  <li>
                    <Link to="https://c4rnhk.org" target="_blink">
                      <FaChevronRight /> Centre for Research - NHK
                    </Link>
                  </li>

                  <li>
                    <Link to="#">
                      <FaChevronRight /> Become a Member
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget widget-recent-post">
                <h4 className="widget-title">Contact Info</h4>
                <div className="widget widget_contact">
                  <ul className="details">
                    <li>
                      <FaMapMarkedAlt />
                      Nephrology and Transplant Unit, National Hospital, Kandy,
                      Sri Lanka.
                    </li>
                    <li className="mt-3">
                      <FaPhoneAlt />
                      <Link to="tel:++94812200449">+94 81 220 0449</Link>
                    </li>
                    <li className="mt-2">
                      <FaEnvelope />
                      <Link to="mailto:info@kkps.lk">info@kkps.lk</Link>
                    </li>
                  </ul>
                  <ul className="social-media mt-4">
                    <li>
                      <a
                        href="https://www.facebook.com/KandyKidneyProtectionSociety"
                        target="_blink"
                      >
                        <FaFacebookF />
                      </a>
                    </li>
                    {/*
                    <li>
                      <a href='#'>
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaYoutube />
                      </a>
                    </li>
                    */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <p>
                  {" "}
                  Copyright © {currentYear} | Kandy Kidney Protection Society.
                  All Rights Reserved. <br />
                  Site Designed and Developed by{" "}
                  <a
                    href="https://chamudithatennekoon.github.io"
                    target="_blink"
                  >
                    Chamuditha Tennekoon
                  </a>
                </p>
              </div>
              <div className="col-md-6 text-lg-end">
                <a href="#">Trams &amp; Condition</a>
                <a href="#">Privacy Policy</a>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* ================== Footer Two  end ==================*/}
    </>
  );
};

export default FooterTwo;
