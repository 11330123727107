import React, { useState } from "react";
import { FaPlus, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaHeart } from "react-icons/fa";

const NavbarThree = () => {
  const [active, setActive] = useState(false);
  const [searchShow, setSearchShow] = useState(false);

  const menuActive = () => {
    setActive(!active);
  };

  const searchActive = () => {
    setSearchShow(!searchShow);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      {/* search popup start
      <div
        className={searchShow ? "td-search-popup active" : "td-search-popup "}
        id="td-search-popup"
      >
        <form action="/" className="search-form">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search....."
            />
          </div>
          <button type="submit" className="submit-btn">
            <FaSearch />
          </button>
        </form>
      </div>
    */}

      {/* search popup end
      <div
        onClick={searchActive}
        className={searchShow ? "body-overlay active" : "body-overlay"}
        id="body-overlay"
      ></div>
      */}

      {/* ==================== Navbar Two Start ====================*/}
      <nav className="navbar navbar-area navbar-area-2 navbar-expand-lg bg-white sticky-active">
        <div className="container nav-container custom-container">
          <div className="responsive-mobile-menu">
            <button
              onClick={menuActive}
              className={
                active
                  ? "menu toggle-btn d-block d-lg-none open"
                  : "menu toggle-btn d-block d-lg-none"
              }
              data-target="#itech_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div>
          <div className="logo">
            <Link to="/" onClick={scrollToTop}>
              <img src="assets/img/kkps_logo.png" alt="img" />
            </Link>
          </div>

        {/*
          <div className="nav-right-part nav-right-part-mobile">
            <span className="search-bar-btn" onClick={searchActive}>
              <FaSearch />
            </span>
          </div>
        */}

          <div
            className={
              active
                ? "collapse navbar-collapse sopen"
                : "collapse navbar-collapse"
            }
            id="itech_main_menu"
          >
            <ul className="navbar-nav menu-open text-lg-end">
              {/*
              <li className="menu-item-has-children">
              */}

              <li>
                <Link to="/" onClick={scrollToTop}>
                  Home
                </Link>

                {/*
                <ul className="sub-menu">
                  <li>
                    <Link to="/" onClick={scrollToTop}>
                      Home 01
                    </Link>
                  </li>
                  <li>
                    <Link to="/index-2" onClick={scrollToTop}>
                      Home 02
                    </Link>
                  </li>
                  
                  <li>
                    <Link to='/index-3' onClick={scrollToTop}>Home 03</Link>
                  </li>
                  <li>
                    <Link to='/index-4' onClick={scrollToTop}>Home 04</Link>
                  </li>
                  <li>
                    <Link to='/index-5'>Home 05</Link>
                  </li>
                  <li>
                    <Link to='/service'>Services</Link>
                  </li>
                  <li>
                    <Link to='/service-details'>Service Single</Link>
                  </li>
                  <li>
                    <Link to='/about'>About Us</Link>
                  </li>
                  <li>
                    <Link to='/team'>Team</Link>
                  </li>
                  <li>
                    <Link to='/team-details'>Team Details</Link>
                  </li>
                  <li>
                    <Link to='/case-study-details'>Case Study Details</Link>
                  </li>
                  <li>
                    <Link to='/blog'>Blog</Link>
                  </li>
                  <li>
                    <Link to='/blog-details'>Blog Details</Link>
                  </li>

                </ul>
              */}
              </li>
              {/* Add other menu items with appropriate links and onClick event */}
              <li>
                <Link to="/service" onClick={scrollToTop}>
                  Services
                </Link>
              </li>
              <li>
                <Link to="/about" onClick={scrollToTop}>
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={scrollToTop}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="nav-right-part nav-right-part-desktop d-lg-inline-flex align-item-center">
            <a className="navbar-phone d-xl-inline-block " href="tel:">
              <span className="icon">
                <img src="assets/img/icon/1.png" alt="img" />
              </span>
              <span>Need help?</span>
              <h5>+94 81 220 0449</h5>
            </a>
            <Link
              className="btn btn-border-base"
              to="/donation"
              onClick={scrollToTop}
            >
              Make a Donation <FaHeart />
            </Link>
          </div>
        </div>
      </nav>
      {/* ==================== Navbar Two end ====================*/}
    </>
  );
};

export default NavbarThree;
