let donationList = [
  {
    title: "Bank of Ceylon , Kandy",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    acc: "7054824",
    bank: "7010",
    branch: "454",
    swift: "BCEYLKLX",
    img: "assets/img/banks/Boc.png",
  },
  {
    title: "Bank of Ceylon , Kandy",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    acc: "34393",
    bank: "7010",
    branch: "454",
    swift: "BCEYLKLX",
    img: "assets/img/banks/Boc.png",
  },
  {
    title: "Bank of Ceylon , Kandy",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    acc: "132617",
    bank: "7010",
    branch: "454",
    swift: "BCEYLKLX",
    img: "assets/img/banks/Boc.png",
  },
  {
    title: "Bank of Ceylon , Kandy",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    acc: "132858",
    bank: "7010",
    branch: "454",
    swift: "BCEYLKLX",
    img: "assets/img/banks/Boc.png",
  },
  {
    title: "Bank of Ceylon , Kandy",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    acc: "190170",
    bank: "7010",
    branch: "454",
    swift: "BCEYLKLX",
    img: "assets/img/banks/Boc.png",
  },
  {
    title: "People's Bank, Kandy",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    acc: "003-2-001-12-0005757",
    bank: "7135",
    branch: "3",
    swift: "PSBKLKLX",
    img: "assets/img/banks/Peoples.png",
  },
  {
    title: "People's Bank, Peradeniya",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    acc: "057-2-001-8-0002070",
    bank: "7135",
    branch: "57",
    swift: "PSBKLKLX",
    img: "assets/img/banks/Peoples.png",
  },
  {
    title: "National Savings Bank",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    acc: "1-0015-01-04256",
    bank: "7719",
    branch: "15",
    swift: "NSBALKLX",
    img: "assets/img/banks/nsb.png",
  },
  {
    title: "Commercial Bank, Kandy",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    acc: "8040053135",
    bank: "7056",
    branch: "4",
    swift: "CCEYLKLX",
    img: "assets/img/banks/ComBank.png",
  },
  {
    title: "Sampath Bank",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    acc: "11060367733",
    bank: "7278",
    branch: "7",
    swift: "BSAMLKLX",
    img: "assets/img/banks/sampath.png",
  },
  {
    title: "Seylan Bank, Kandy",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    acc: "0170-30404117-101",
    bank: "7287",
    branch: "17",
    swift: "SEYBLKLX",
    img: "assets/img/banks/Seylan.png",
  },
];

export default donationList;
