import React from "react";
import AboutAreaOne from "../components/AboutAreaOne";
import Breadcrumb from "../components/Breadcrumb";
import ContactAreaOne from "../components/ContactAreaOne";
import CounterAreaOne from "../components/CounterAreaOne";
import FaqAreaOne from "../components/FaqAreaOne";
import FooterTwo from "../components/FooterTwo";
import NavBar from "../components/NavBar";
import TeamAreaOne from "../components/TeamAreaOne";
import WorkProcessOne from "../components/WorkProcessOne";
import NavbarThree from "../components/NavbarThree";
import AboutAreaFive from "../components/AboutAreaFive";
import AboutAreaFour from "../components/AboutAreaFour";
import AboutAreaTwo from "../components/AboutAreaTwo";
import AboutAreaThree from "../components/AboutAreaThree";
import AboutAreaSix from "../components/AboutAreaSix";
import FaqAreaTwo from "../components/FaqAreaTwo";

const About = () => {
  return (
    <>
      {/* Navigation Bar 
      <NavBar />
      */}
      <NavbarThree />

      {/* Navigation Bar */}
      <Breadcrumb title={"About Us"} />

      {/* About Area One */}
      <AboutAreaOne />

      {/* FAQ Area One */}
      <FaqAreaOne />

      {/* Team Area One */}
      <TeamAreaOne />

      {/* Counter Area One 
      <CounterAreaOne />

      {/* Contact Area One 
      <ContactAreaOne />


      {/* Work Process One 
      <WorkProcessOne />
    */}

      {/* Footer One */}
      <FooterTwo />
    </>
  );
};

export default About;
